import { MANAGER_API, API } from '@/utilities/http-common'

const getSessions = (year, program) => {
  return MANAGER_API.get(`/programs/${program}/sessions`, {
    year: year
  }).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.sessions
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

const getSession = id => {
  return API.get(`/sessions/${id}`).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.session
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

const getShallowSessions = ids => {
  return API.get('/sessions/shallow', {
    params: {
      ids: ids
    }
  }).then(response => {
    if (response.status === 200) {
      const data = response.data
      return data.sessions
    } else {
      const error = new Error(response.statusText)
      error.code = response.status
      throw error
    }
  })
}

export { getSessions, getSession, getShallowSessions }
