<template>
  <div>
    <cs-loading v-if="$wait.is('loading.students')">
      Loading Students
    </cs-loading>
    <cs-loading v-if="$wait.is('loading.courses')">
      Loading Courses
    </cs-loading>
    <cs-loading v-if="$wait.is('loading.sessions')">
      Loading Sessions
    </cs-loading>
    <div v-if="!loading" class="row px-2">
      <div v-for="student in students"
           :key="student.id"
           class="col-4 py-1">
        <t-shirt-picker :student="student"
                        :registration_id="registration_id"
                        :course="courseForStudent(student)"
                        :session="sessionForStudent(student)"
                        :initial_size="initialSizeForStudent(student)"
                        @selected="debounced"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { getShallowStudents } from '@/api/studentAPI'
import { getShallowCourses } from '@/api/courseAPI'
import { getShallowSessions } from '@/api/sessionAPI'
import { debounce } from '@/utilities/utilities'
import TShirtPicker from '@/components/t_shirts/TShirtPicker'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'TShirtsPortal',
  components: { TShirtPicker },
  props: {
    students_need_t_shirt_size: {
      type: Array,
      default: () => [],
      required: true
    },
    students_with_t_shirt_size: {
      type: Array,
      default: () => [],
      required: true
    },
    registration_id: {
      type: Number,
      required: true
    },
    error: {
      type: Object,
      default: () => { return {} },
      required: false
    }
  },
  data () {
    return {
      students: [],
      courses: [],
      sessions: [],
      debounced: null
    }
  },
  destroyed () {
    this.debounced = null
  },
  created () {
    const studentID = []
    const courseID = []
    const sessionID = []
    this.debounced = debounce(this.reloadThisPage, 3000, {
      maxWait: 5000,
      trailing: true
    })
    this.students_need_t_shirt_size.forEach(student => {
      console.log('need', student)
      if (student.student_id !== null) {
        studentID.push(student.student_id)
      }
      if (student.course_id !== null) {
        if (Array.isArray(student.course_id)) {
          student.course_id.forEach(id => {
            courseID.push(id)
          })
        } else {
          courseID.push(student.course_id)
        }
      }
      if (student.session_id !== null) {
        sessionID.push(student.session_id)
      }
    })
    this.students_with_t_shirt_size.forEach(student => {
      console.log('with', student)
      studentID.push(student.student_id)
    })
    if (studentID.length > 0) {
      this.$wait.start('loading.students')
      getShallowStudents(studentID).then(response => {
        console.log('students', response)
        this.students = response
        this.$wait.end('loading.students')
      })
    }
    if (courseID.length > 0) {
      this.$wait.start('loading.courses')
      getShallowCourses(courseID).then(response => {
        console.log('courses', response)
        this.courses = response
        this.$wait.end('loading.courses')
      })
    }
    if (sessionID.length > 0) {
      this.$wait.start('loading.sessions')
      getShallowSessions(sessionID).then(response => {
        console.log('sessions', response)
        this.sessions = response
        this.$wait.end('loading.sessions')
      })
    }
  },
  computed: {
    loading () {
      return this.$wait.is(['loading.students', 'loading.courses', 'loading.sessions'])
    }
  },
  methods: {
    reloadThisPage () {
      window.location.reload()
    },
    courseForStudent (student) {
      const found = this.students_need_t_shirt_size.find(studentWithOutSize => {
        return parseInt(student.id) === parseInt(studentWithOutSize.student_id)
      })
      if (!isEmpty(found) && found.course_id) {
        return this.courses.find(course => {
          console.log('course', found, course)
          return found.course_id === course.id
        })
      }
      return {}
    },
    sessionForStudent (student) {
      const found = this.students_need_t_shirt_size.find(studentWithOutSize => {
        return parseInt(student.id) === parseInt(studentWithOutSize.student_id)
      })
      if (!isEmpty(found) && found.session_id) {
        return this.sessions.find(session => {
          console.log('session', found, session)
          return found.session_id === session.id
        })
      }
      return {}
    },
    initialSizeForStudent (student) {
      const found = this.students_with_t_shirt_size.find(studentWithSize => {
        return parseInt(student.id) === parseInt(studentWithSize.student_id)
      })
      if (!isEmpty(found)) {
        return found.size
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>

</style>
